<div class="p-3 overflow-auto">
  <div class="awards-cards" *ngIf="awardList && awardList?.length">
    <!--   -->
    <div class="awards-card" *ngFor="let award of awardList">
      <figure class="awards-figure">
        <img
          class="img-fluid"
          [src]="award | getImageForAwards : 'competition'"
          [alt]="award.award_title"
        />
      </figure>
      <h6 class="mt-2 mb-3 text-black">
        <div>{{ award?.award_title }}</div>
        <div class="text-muted fs-6">
          {{
            award?.award_category == "country"
              ? award?.country_name
              : award?.award_category == "regional"
              ? award?.region_name
              : ""
          }}
        </div>
      </h6>
      <p class="d-flex align-items-center justify-content-center gap-2 m-0">
        <span class="cursor-pointer" (click)="viewAward(award)"
          >View Award</span
        >
        <img
          (click)="viewAward(award)"
          class="cursor-pointer"
          src="../../../../assets/competitions/arrow-right.svg"
          alt="View Award"
        />
      </p>
    </div>
  </div>
</div>
