<!-- competition cards -->
<div class="competition-cards">
  <div class="card shadow-none" *ngFor="let competition of updatedCompetitions | sortCompetitions">
    <div class="card-body d-flex flex-column">
      <div class="d-flex flex-column gap-3 pb-0">
        <figure class="card-figure m-0">
          <img
            class="img-fluid"
            [ngSrc]="
              competition.thumbnail
                ? imageBaseURL + 'common/getfile/' + competition.thumbnail
                : '../../../../assets/images/student-portal/competition-img.png'
            "
            [width]="320"
            [height]="180"
            [alt]="competition?.name ?? 'Competition Image'"
          />

          <button
            class="btn btn-white btn-share"
            (click)="share(competition.details)"
          >
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 17.3332C12.3056 17.3332 11.7153 17.0901 11.2292 16.604C10.7431 16.1179 10.5 15.5276 10.5 14.8332C10.5 14.7359 10.5069 14.6353 10.5208 14.5311C10.5347 14.4269 10.5556 14.3332 10.5833 14.2498L4.70833 10.8332C4.47222 11.0415 4.20833 11.2047 3.91667 11.3228C3.625 11.4408 3.31944 11.4998 3 11.4998C2.30556 11.4998 1.71528 11.2568 1.22917 10.7707C0.743056 10.2846 0.5 9.69428 0.5 8.99984C0.5 8.30539 0.743056 7.71511 1.22917 7.229C1.71528 6.74289 2.30556 6.49984 3 6.49984C3.31944 6.49984 3.625 6.55886 3.91667 6.67692C4.20833 6.79498 4.47222 6.95817 4.70833 7.1665L10.5833 3.74984C10.5556 3.6665 10.5347 3.57275 10.5208 3.46859C10.5069 3.36442 10.5 3.26373 10.5 3.1665C10.5 2.47206 10.7431 1.88178 11.2292 1.39567C11.7153 0.909559 12.3056 0.666504 13 0.666504C13.6944 0.666504 14.2847 0.909559 14.7708 1.39567C15.2569 1.88178 15.5 2.47206 15.5 3.1665C15.5 3.86095 15.2569 4.45123 14.7708 4.93734C14.2847 5.42345 13.6944 5.6665 13 5.6665C12.6806 5.6665 12.375 5.60748 12.0833 5.48942C11.7917 5.37136 11.5278 5.20817 11.2917 4.99984L5.41667 8.4165C5.44444 8.49984 5.46528 8.59359 5.47917 8.69775C5.49306 8.80192 5.5 8.90261 5.5 8.99984C5.5 9.09706 5.49306 9.19775 5.47917 9.30192C5.46528 9.40609 5.44444 9.49984 5.41667 9.58317L11.2917 12.9998C11.5278 12.7915 11.7917 12.6283 12.0833 12.5103C12.375 12.3922 12.6806 12.3332 13 12.3332C13.6944 12.3332 14.2847 12.5762 14.7708 13.0623C15.2569 13.5484 15.5 14.1387 15.5 14.8332C15.5 15.5276 15.2569 16.1179 14.7708 16.604C14.2847 17.0901 13.6944 17.3332 13 17.3332Z"
                fill="#595959"
              />
            </svg>
          </button>
        </figure>
        <div class="flex-grow-1 overflow-hidden mb-2">
          <h6
            class="card-title mt-2 text-break"
            [title]="competition?.name"
          >
            {{ competition?.name }}
          </h6>

          <!-- Priority Registration -->
          <div
            class="text-priority d-flex align-items-center gap-2"
            *ngIf="isRegisterationOpen"
          >
            <span class="d-flex" *ngIf="!competition?.isUpcoming">
              <!-- [ngClass]="{
                  'in-progress': 
                }" -->
              <svg
                *ngIf="competition?.status === 'In Progress'"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.68893 17.3768C7.68616 17.2712 6.75265 16.9908 5.88841 16.5356C5.02418 16.0804 4.27539 15.4999 3.64206 14.7939C3.00872 14.088 2.51063 13.2799 2.14779 12.3695C1.78494 11.4591 1.60352 10.4959 1.60352 9.47988C1.60352 7.43475 2.27973 5.66339 3.63216 4.16582C4.98459 2.66825 6.67678 1.80731 8.70873 1.58301V3.16634C7.1122 3.39065 5.79275 4.09985 4.75039 5.29395C3.70803 6.48804 3.18685 7.88336 3.18685 9.47988C3.18685 11.0764 3.70803 12.4717 4.75039 13.6658C5.79275 14.8599 7.1056 15.5691 8.68893 15.7934V17.3768ZM10.2723 17.3768V15.7934C10.8396 15.7143 11.3839 15.5625 11.9051 15.3382C12.4263 15.1139 12.9112 14.8302 13.3598 14.4872L14.5077 15.6351C13.8875 16.1233 13.2212 16.5158 12.5087 16.8127C11.7962 17.1096 11.0507 17.2976 10.2723 17.3768ZM13.3994 4.47259C12.9375 4.12954 12.4461 3.84586 11.9249 3.62155C11.4037 3.39724 10.8594 3.24551 10.2921 3.16634V1.58301C11.0705 1.66217 11.816 1.8502 12.5285 2.14707C13.241 2.44395 13.9007 2.83648 14.5077 3.32467L13.3994 4.47259ZM15.616 14.4872L14.5077 13.3591C14.8507 12.9104 15.1278 12.4255 15.3389 11.9044C15.55 11.3832 15.6952 10.8389 15.7744 10.2716H17.3973C17.2917 11.05 17.0938 11.7988 16.8035 12.5179C16.5132 13.237 16.1174 13.8934 15.616 14.4872ZM15.7744 8.68822C15.6952 8.12086 15.55 7.57659 15.3389 7.0554C15.1278 6.53422 14.8507 6.04933 14.5077 5.60072L15.616 4.47259C16.1174 5.06634 16.5198 5.72277 16.8233 6.44186C17.1268 7.16096 17.3181 7.90975 17.3973 8.68822H15.7744Z"
                  fill="#0CBD80"
                />
              </svg>
              <svg
                *ngIf="competition?.status !== 'In Progress'"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0423 11.2104L6.50065 7.25212L0.958984 11.2104L0.958984 9.27087L6.50065 5.31253L12.0423 9.27087L12.0423 11.2104ZM12.0423 6.50003L6.50065 2.5417L0.958984 6.50003L0.958984 4.56045L6.50065 0.602115L12.0423 4.56045L12.0423 6.50003Z"
                  fill="#E54F6D"
                />
              </svg>
            </span>
            <span class="d-flex" *ngIf="competition?.isUpcoming">
              <img
                src="../../../../assets/svgs/competition-status.svg"
                alt=""
                srcset=""
              />
            </span>

            <span
              class="text-uppercase fw-bolder"
              [ngClass]="{
                'text-primary': competition?.isUpcoming,
                'in-progress': competition?.status === 'In Progress'
              }"
            >
              {{
                competition?.isUpcoming
                  ? "Coming Soon"
                  : competition?.status != "Registration"
                  ? competition?.status
                  : competition?.registration_stage + " " + "Registration"
              }}
            </span>
          </div>
          <!-- End: Priority Registration -->

          <!-- Registration Closed -->
          <div
            class="text-reg-close d-flex align-items-center gap-2"
            *ngIf="!isRegisterationOpen"
          >
            <span class="d-flex align-items-center">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.50065 16.4168C7.40551 16.4168 6.37635 16.209 5.41315 15.7934C4.44996 15.3778 3.61211 14.8137 2.89961 14.1012C2.18711 13.3887 1.62305 12.5509 1.20742 11.5877C0.791797 10.6245 0.583984 9.5953 0.583984 8.50016C0.583984 7.40502 0.791797 6.37586 1.20742 5.41266C1.62305 4.44947 2.18711 3.61162 2.89961 2.89912C3.61211 2.18662 4.44996 1.62256 5.41315 1.20693C6.37635 0.791309 7.40551 0.583496 8.50065 0.583496C9.59579 0.583496 10.625 0.791309 11.5882 1.20693C12.5513 1.62256 13.3892 2.18662 14.1017 2.89912C14.8142 3.61162 15.3783 4.44947 15.7939 5.41266C16.2095 6.37586 16.4173 7.40502 16.4173 8.50016C16.4173 9.5953 16.2095 10.6245 15.7939 11.5877C15.3783 12.5509 14.8142 13.3887 14.1017 14.1012C13.3892 14.8137 12.5513 15.3778 11.5882 15.7934C10.625 16.209 9.59579 16.4168 8.50065 16.4168ZM8.50065 14.8335C9.21315 14.8335 9.89926 14.718 10.559 14.4871C11.2187 14.2562 11.8257 13.9231 12.3798 13.4877L3.51315 4.621C3.07773 5.17516 2.74457 5.78211 2.51367 6.44183C2.28277 7.10155 2.16732 7.78766 2.16732 8.50016C2.16732 10.2682 2.78086 11.7658 4.00794 12.9929C5.23503 14.22 6.7326 14.8335 8.50065 14.8335ZM13.4882 12.3793C13.9236 11.8252 14.2567 11.2182 14.4876 10.5585C14.7185 9.89877 14.834 9.21266 14.834 8.50016C14.834 6.73211 14.2204 5.23454 12.9934 4.00745C11.7663 2.78037 10.2687 2.16683 8.50065 2.16683C7.78815 2.16683 7.10204 2.28228 6.44232 2.51318C5.7826 2.74409 5.17565 3.07725 4.62148 3.51266L13.4882 12.3793Z"
                  fill="#8C93C1"
                />
              </svg>
            </span>
            <span class="text-uppercase fw-bolder"> Registration Closed </span>
          </div>
          <!-- End: Registration Closed -->

          <!-- timer section -->
          <div class="mt-2">
            <!-- ENDS IN -->
            <div
              class="d-flex align-items-center gap-2"
              *ngIf="isRegisterationOpen"
            >
              <span>
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.01331 0.164062C4.88768 0.164063 4.76719 0.21397 4.67835 0.302806C4.58952 0.391642 4.53961 0.51213 4.53961 0.637764C4.53961 0.763397 4.58952 0.883885 4.67835 0.972721C4.76719 1.06156 4.88768 1.11146 5.01331 1.11146H7.85552C7.98115 1.11146 8.10164 1.06156 8.19047 0.972721C8.27931 0.883885 8.32922 0.763397 8.32922 0.637764C8.32922 0.51213 8.27931 0.391642 8.19047 0.302806C8.10164 0.21397 7.98115 0.164063 7.85552 0.164062H5.01331ZM12.1188 7.74328C12.1188 10.8825 9.57363 13.4277 6.43441 13.4277C3.2952 13.4277 0.75 10.8825 0.75 7.74328C0.75 4.60406 3.2952 2.05887 6.43441 2.05887C9.57363 2.05887 12.1188 4.60406 12.1188 7.74328ZM11.1714 7.74328C11.1714 5.12703 9.05066 3.00627 6.43441 3.00627C3.81816 3.00627 1.6974 5.12703 1.6974 7.74328C1.6974 10.3595 3.81816 12.4803 6.43441 12.4803C9.05066 12.4803 11.1714 10.3595 11.1714 7.74328ZM11.5068 2.19766C11.4631 2.15242 11.4108 2.11633 11.353 2.0915C11.2952 2.06668 11.2331 2.05361 11.1702 2.05306C11.1073 2.05252 11.0449 2.0645 10.9867 2.08832C10.9285 2.11214 10.8756 2.14731 10.8311 2.19179C10.7866 2.23627 10.7515 2.28916 10.7277 2.34737C10.7038 2.40559 10.6918 2.46797 10.6924 2.53086C10.6929 2.59376 10.706 2.65592 10.7308 2.71371C10.7557 2.77151 10.7917 2.82378 10.837 2.86747L11.7844 3.81488C11.8284 3.85886 11.8806 3.89374 11.9381 3.91753C11.9956 3.94132 12.0572 3.95355 12.1195 3.95353C12.1817 3.95351 12.2433 3.94123 12.3008 3.9174C12.3582 3.89357 12.4105 3.85865 12.4544 3.81464C12.4984 3.77063 12.5333 3.71838 12.5571 3.66089C12.5809 3.6034 12.5931 3.54179 12.5931 3.47957C12.5931 3.41735 12.5808 3.35574 12.557 3.29826C12.5331 3.24079 12.4982 3.18857 12.4542 3.14459L11.5068 2.19766Z"
                    fill="#595959"
                  />
                  <path
                    d="M6.2001 11.5323C7.26799 11.5323 8.29213 11.1331 9.04724 10.4224C9.80235 9.7117 10.2266 8.7478 10.2266 7.74273C10.2266 6.73767 9.80235 5.77376 9.04724 5.06308C8.29213 4.35239 7.26799 3.95312 6.2001 3.95313V7.74273V11.5323Z"
                    fill="#0CBD80"
                  />
                </svg>
              </span>
              <span class="text-endsin"> ENDS IN </span>
            </div>
            <div class="text-endsin" *ngIf="!isRegisterationOpen">
              Registration Deadline
            </div>
            <!-- ENDS IN -->

            <!-- timer -->
            <div class="d-flex flex-wrap timer-joiner-section">
              <app-countdown-timer
                *ngIf="isRegisterationOpen"
                [endTime]="competition?.status_time_update"
                [isUpcomingCompetition]="
                  competition?.status == 'Registration' ? false : true
                "
              ></app-countdown-timer>

              <div
                *ngIf="!isRegisterationOpen"
                class="timer-section flex-grow-1 px-2 ps-0"
              >
                <span class="text-time">{{
                  competition?.regular_registration_close
                    | date : "dd MMM yy, hh:mm a"
                }}</span>
              </div>
              <!-- End: timer section -->
            </div>
          </div>
        </div>
      </div>
      <!-- Join Now Buttons -->
      <div
        class="d-flex gap-2 pt-3 competition-card-footer mt-auto"
        *ngIf="
          isRegisterationOpen && !competition?.isUpcoming;
          else notUpcoming
        "
      >
        <button
          class="btn btn-join-now d-flex justify-content-center gap-2 w-100"
          (click)="storeCompetition(competition)"
          [disabled]="
            (!isUserLoggedIn && competition?.registration_stage == 'Closed') ||
            competition?.team_disqualified ||
            (!competition?.isJoined && competition?.status !== 'Registration')
          "
          *ngIf="
            !competition?.team_disqualified &&
            ((competition?.status == 'In Progress' && competition?.isJoined) ||
              competition?.status == 'Registration')
          "
        >
          <span
            class="text-decoration-underline link-underline-light link-offset-1"
          >
            {{ competition?.isJoined ? "My Progress" : "Join now" }}</span
          >
          <span>
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.33073 10.9974L0.164062 9.83073L8.16406 1.83073H0.997396V0.164062H10.9974V10.1641H9.33073V2.9974L1.33073 10.9974Z"
                fill="white"
              />
            </svg>
          </span>
        </button>
        <button
          type="button"
          (click)="navigatetoDetails(competition?.details)"
          class="btn btn-view-details link-dark link-underline-opacity-100-hover w-100 d-flex justify-content-center align-items-center"
        >
          View Details
        </button>
      </div>

      <ng-template #notUpcoming>
        <div class="d-flex flex-column pt-3 mt-auto">
          <button
            class="btn btn-outline-primary w-100 rounded opacity-100 fw-medium"
            disabled
          >
            Coming Soon...
          </button>
        </div>
      </ng-template>
      <!-- End: Join Now Buttons -->
    </div>
    <!-- End: competition cards -->
  </div>
</div>
