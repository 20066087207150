import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPreviousRoundDetails',
})
export class GetPreviousRoundDetailsPipe implements PipeTransform {
  transform(value: any, team_disqualified_round: number) {
    const roundDetails = value.rounds.find((round) => {
      return round.round_number == team_disqualified_round;
    });

    if (roundDetails) {
      return roundDetails['awards']; 
    } else {
      return false;
    }
  }
}
