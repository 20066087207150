import { Component } from '@angular/core';
import { Award } from '../../models/competions.models';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-award-list',
  templateUrl: './view-award-list.component.html',
  styleUrls: ['./view-award-list.component.scss'],
})
export class ViewAwardListComponent {
  public awardList: Award[] = [];

  constructor(
    private commonService: CommonServiceService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router
  ) {}

  ngOnInit() {
    this.getAwardsDetails();
  }

  getAwardsDetails() {
    this.commonService
      .getList(
        `users/competitions/awards/${this.config?.data?.competitionData?.id}/${this.config?.data?.competitionData.team_disqualified_round}`
      )
      .subscribe(
        (res) => {
          this.awardList = res && res.data;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  viewAward(award: any) {
    const userData =
      localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

    const teamAwardId = award.team_award_id;

    if (userData) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/certification'], {
          queryParams: { team_award_id: teamAwardId, user_id: userData.id },
        })
      );

      window.open(url, '_blank'); 
    }
  }
}
