import { Component, HostListener, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../shared/common-service.service';
import { localFormatDateToMMMDDYYYY } from '../shared/services/shared-methods.service';
import { CertificateCanvasComponent } from './certificate-canvas/certificate-canvas.component';
import { CompetitionAwardData } from './models/competition.interface';
import { DecryptService } from '../shared/decrypt.service';

@Component({
  selector: 'app-certificate-award',
  templateUrl: './certificate-award.component.html',
  styleUrls: ['./certificate-award.component.scss'],
})
export class CertificateAwardComponent {
  @ViewChild(CertificateCanvasComponent)
  certificateCanvas!: CertificateCanvasComponent;
  @HostListener('window:resize', ['$event']) checkDeviceType(): void {
    const isMobile = window.innerWidth <= 991;
    this.deviceType = isMobile ? 'Mobile' : 'Desktop';
    if (this.deviceType === 'Mobile') {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  public deviceType: string;
  public isMobileView: boolean = false;
  public competitionAwardData: CompetitionAwardData;
  public isLoggedInUser: boolean;
  public teamAwardId: string;
  public imageBaseUrl: string = 'common/getfile/';
  public isVerifiedModalVisible: boolean = false;

  public verificationInfo: string[] = [];
  public window = window;
  public competitionAwardbaseUrl: string = environment.apiUrl;
  public competitionAwardIconUrl: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonServiceService,
    private router: Router,
    private renderer: Renderer2,
    private decryptService: DecryptService
  ) {}

  ngOnInit(): void {
    this.checkDeviceType();

    this.isLoggedInUser =
      (localStorage.getItem('user') &&
        JSON.parse(localStorage.getItem('user'))) ||
      false;

    this.activatedRoute.queryParams.subscribe((params) => {
      const encryptedData = params['Algoed'];

      if (encryptedData) {
        // Decrypt the Algoed query parameter
        const decryptedData = this.decryptService.decrypt(encryptedData);
        const parsedData = JSON.parse(decryptedData); // Assuming it's JSON

        this.teamAwardId = parsedData.team_award_id;
        const userId = parsedData.user_id;

        // Fetch the award data based on decrypted query params
        this.getCompetitionAwardData(this.teamAwardId, userId);
      } else {
        // If no Algoed query param is found, fall back to plain query params
        this.teamAwardId = params['team_award_id'];
        const userId = params['user_id'];

        if (this.teamAwardId && userId) {
          // Encrypt and replace the URL with the Algoed query params
          this.encryptAndReplaceUrl(this.teamAwardId, userId);
          this.getCompetitionAwardData(this.teamAwardId, userId);
        }
      }
      // this.(this.teamAwardId, userId);
    });
  }

  encryptAndReplaceUrl(teamAwardId: string, userId: string): void {
    const queryParams = {
      team_award_id: teamAwardId,
      user_id: userId,
    };

    const encryptedData = this.decryptService.encrypt(queryParams);

    if (encryptedData?.data) {
      const newUrl = this.router.createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { Algoed: encryptedData.data },
        queryParamsHandling: '',
      });

      // Remove the plain `team_award_id` and `user_id` from the query params
      this.router.navigateByUrl(newUrl);
    }
  }

  setOgImageMetaTag(imageUrl: string): void {
    const metaTag = document.querySelector("meta[property='og:image']");

    if (metaTag) {
      // If the meta tag already exists, update the content
      this.renderer.setAttribute(metaTag, 'content', imageUrl);
    } else {
      // Create a new meta tag if it doesn't exist
      const newMetaTag = this.renderer.createElement('meta');
      this.renderer.setAttribute(newMetaTag, 'property', 'og:image');
      this.renderer.setAttribute(newMetaTag, 'content', imageUrl);
      this.renderer.appendChild(document.head, newMetaTag);
    }
  }

  async uploadCertificateImage(canvasDataUrl: string) {
    const formData = new FormData();
    formData.append('file', canvasDataUrl);

    const finalData = {
      isCategorList: true,
      name: formData,
    };
    this.commonService
      .saveRecord(
        `users/competitions/awards/${this.teamAwardId}/upload`,
        finalData
      )
      .subscribe({
        next: (res) => {
          this.imageBaseUrl = environment.apiUrl + 'common/getfile/' + res.data;
          // console.log(certificateName, certificateURL, certificateImageURL);
          // this.setOgImageMetaTag(certificateImageURL);
          const competitionAward = this.competitionAwardData.competition_award;

          const certificateName =
            competitionAward?.award_title || 'Certificate';
          const certificateURL = window.location.href;
          const linkedInURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            this.imageBaseUrl
          )}&title=${encodeURIComponent(
            certificateName
          )}&summary=${encodeURIComponent(
            'I have received this certificate for my achievements.'
          )}&source=${encodeURIComponent(certificateURL)}`; // Sharing page URL, not image

          window.open(linkedInURL, '_blank', 'width=600,height=400');
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  async addToLinkedInProfile() {
    this.uploadCertificateImage(this.getCertificateImageURL());
  }

  getCertificateImageURL(): string {
    if (this.certificateCanvas) {
      const canvasElement = this.certificateCanvas.canvasRef.nativeElement;
      return canvasElement.toDataURL('image/jpg');
    }
    return '';
  }

  navigateToHome() {
    const isLoggedInUser = localStorage.getItem('user');
    if (isLoggedInUser) {
      this.router.navigate(['/student/competitions/startup']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  downloadImage() {
    if (this.certificateCanvas) {
      this.certificateCanvas.downloadImage();
    }
  }

  downloadToPdfAward() {
    this.certificateCanvas.downloadPDFWithoutLibrary();
  }

  getCompetitionAwardData(team_award_id: string, user_id: string) {
    this.commonService
      .getList(
        `users/competitions/awards/details/${+team_award_id}/${+user_id}`
      )
      .subscribe({
        next: (res: any) => {
          this.competitionAwardData = res.data;
          this.getVerificationInfo(this.competitionAwardData);

          const competitionAwardIcon = this.competitionAwardData.images.find(
            (image) => {
              return image.type === 'competition';
            }
          ).image;
          this.competitionAwardIconUrl =
            this.competitionAwardbaseUrl +
            'common/getfile/' +
            competitionAwardIcon;
        },
        error: (error) => {
          this.commonService.showToast(
            error?.error?.error ?? 'Something went wrong',
            'error',
            3000
          );
          // if (this.isLoggedInUser) {
          //   this.router.navigate(['/student/competitions/startup']);
          // } else {
          //   this.router.navigate(['/login']);
          // }
          console.log(error);
        },
      });
  }

  openVerifiedModal() {
    this.isVerifiedModalVisible = true;
  }

  getVerificationInfo(competitionAwardData: CompetitionAwardData) {
    if (competitionAwardData) {
      const formattedDate = localFormatDateToMMMDDYYYY(
        competitionAwardData?.award?.createdAt
      );
      this.verificationInfo = [
        `Issued on <strong>${formattedDate ?? ''}</strong>`,
        `Issued by <strong>${
          competitionAwardData?.competition?.name ?? ''
        }</strong>`,
        `Issued using <strong>AlgoEd</strong>`,
        `Issued to <strong>${
          competitionAwardData?.team_member?.name ?? ''
        }</strong> 
          from <strong>${competitionAwardData?.team?.school ?? ''}</strong>`,
      ];
    }
  }
}
